<template>
  <div class="wrap">
    <header class="x-page-title">
      <h3>编辑新闻/动态</h3>
    </header>

    <main class="x-main">
      <!-- 标题类型 -->
      <el-card class="mb30">
        <el-form
          class="x-form"
          ref="titleForm"
          size="small"
          label-width="140px"
          :rules="titleRules"
          :model="form"
        >
          <el-form-item label="标题：" prop="title">
            <el-input
              placeholder="请输入标题"
              maxlength="30"
              v-model="form.title"
            ></el-input>
          </el-form-item>

          <el-form-item label="编辑正文：" prop="content">
            <el-input
              type="textarea"
              :rows="15"
              v-model="form.content"
            ></el-input>
          </el-form-item>
          <el-form-item label="公告图片：" prop="license">
            <el-upload
              class="x-single-upload"
              drag
              ref="upload"
              action="#"
              name="file"
              accept=".jpg, .jpeg, .png"
              :show-file-list="false"
              :file-list="licenseList"
              :on-change="setLicenseList"
              :before-upload="beforeLicenseUpload"
              :http-request="doLicenseUpload"
            >
              <el-image
                v-if="form.license"
                style="width: 360px; height: 180px"
                :src="form.license"
                fit="contain"
              ></el-image>
              <div v-else>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或
                  <em>点击上传</em>
                </div>
              </div>
              <div class="el-upload__tip" slot="tip">
                （支持PNG和JPG格式图片，图片大小不超过10M）
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <el-row type="flex" justify="center" class="mt10">
          <el-col :span="6">
            <el-button type="primary" @click="doValidate">立即发布</el-button>
            <el-button @click="goBack">返回上级页面</el-button>
          </el-col>
        </el-row>
      </el-card>
    </main>
  </div>
</template>

<script>
import { NoticeUpdate } from "@/api/module/notice";
import { FileUpload } from "@/api/common";

// 表单校验规则
const titleRules = {
  title: [
    {
      required: true,
      message: "请输入标题",
      trigger: "blur",
    },
  ],
  content: [
    {
      required: true,
      message: "请编辑内容",
      trigger: "blur",
    },
  ],
  license: [
    {
      required: true,
      message: "请上传公告图片",
      trigger: "change",
    },
  ],
};

export default {
  name: "NoticeUpdate",
  data() {
    return {
      titleRules: titleRules,
      form: {
        id: "",
        content: "",
        title: "",
        license: "",
      },
    };
  },
  created() {
    this.doQuery();
  },
  methods: {
    // 选择轮播图
    setLicenseList(file, fileList) {
      this.licenseList = fileList;
    },
    // 限制轮播图大小与格式
    beforeLicenseUpload(file) {
      if (["jpeg", "png", "jpg"].indexOf(file.type.split("/")[1]) < 0) {
        this.$message.error("上传图片只能是 JPG或PNG 格式!");
        return false;
      }
      const sizeLimit = file.size / 1024 / 1024 < 10;
      if (!sizeLimit) {
        this.$message.error("上传图片大小不能超过 10MB!");
        return false;
      }
      return true;
    },

    // 上传轮播图
    doLicenseUpload() {
      let oData = new FormData();
      const oFileList = this.licenseList;
      const len = oFileList.length;
      for (let i = 0; i < len; i++) {
        oData.append("file", oFileList[i].raw);
      }
      FileUpload(oData).then((r) => {
        this.form.license = r.data.file.url;
        this.$refs["titleForm"].clearValidate("license");
      });
      this.licenseList = [];
    },
    // 获取详情，绑定数据
    doQuery() {
      const q = this.$route.params;
      this.form = {
        id: Number(q.id),
        content: q.content,
        title: q.title,
        license: q.imgUrl,
      };
    },
    // 验证表单
    doValidate() {
      this.$refs["titleForm"].validate((valid) => {
        if (valid) {
          this.doSubmit();
        } else {
          return false;
        }
      });
    },
    // 创建公告请求
    doSubmit() {
      const f = this.form;
      const news = {
        content: f.content,
        title: f.title,
        id: f.id,
        imgUrl: f.license,
      };
      NoticeUpdate(news).then((r) => {
        if (r.code == 0) {
          this.$message({
            type: "success",
            message: "修改成功",
            duration: 1300,
            onClose: () => {
              this.goBack();
            },
          });
        }
      });
    },
    // 返回上级页面
    goBack() {
      this.$router.push(`/notice?tab=first`);
    },
  },
};
</script>